import axios from 'axios'

export const getDinerById = async (id) => {
  const { data } = await axios.get(`/api/v2/diners/${id}`)
  return data
}

export const getDinerByPhoneNumber = async (inputData) => {
  const { data } = await axios.post('/api/v2/diners/phoneNumber/exists', inputData)
  return data
}

export const insertDiner = async (diner) => {
  const dinerTrackingId = localStorage.getItem('trackingId')
  const { data } = await axios.post('/api/v2/diners', { dinerTrackingId, ...diner })
  return data
}

export const updateDiner = (diner) => {
  const dinerTrackingId = localStorage.getItem('trackingId')
  axios.put(`/api/v2/diners/${diner._id}`, { dinerTrackingId, ...diner })
}

export const fetchDinerByAuth = async () => {
  const response = await axios.get('/api/v2/diners/auth')
  if (!response.data) return { data: null }
  return response
}

export const insertRelatedDiner = async (dinerId, relatedDiners) => {
  const { data } = await axios.post(`/api/v2/diners/${dinerId}/relatedDiners`, relatedDiners)
  return data
}

export const updateRelatedDiner = async (dinerId, relatedDinerId, relatedDiner) => {
  const { data } = await axios.put(`/api/v2/diners/${dinerId}/relatedDiners/${relatedDinerId}`, relatedDiner)
  return data
}

export const deleteRelatedDiner = async (dinerId, relatedDinerId) => {
  const { data } = await axios.delete(`/api/v2/diners/${dinerId}/relatedDiners/${relatedDinerId}`)
  return data
}