import { Container, ThemeProvider } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router'

import getTheme from '../theme'
import RTL from 'components/RTL'
import { ProvideDinerOrder } from 'contexts/order'
import LanguageProvider, { useLanguage } from 'contexts/language'
import { useValidateDinerToken } from 'hooks/dinerToken'

function DinerViewWrapper() {
  useValidateDinerToken()

  return (
    <LanguageProvider>
      <ProvideDinerOrder>
        <WrappedDinerView />
      </ProvideDinerOrder>
    </LanguageProvider>
  )
}
function WrappedDinerView() {
  const { isRTL } = useLanguage()

  return isRTL ? (
    <RTL>
      <DinerView />
    </RTL>
  ) : (
    <DinerView />
  )
}

function DinerView() {
  const { isRTL } = useLanguage()

  return (
    <ThemeProvider theme={getTheme(isRTL)}>
      <Container
        maxWidth="sm"
        disableGutters
        sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}
      >
        <Outlet />
      </Container>
    </ThemeProvider>
  )
}

export default DinerViewWrapper
