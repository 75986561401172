import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useKitchenById } from 'hooks/kitchens'
import { useParams, useSearchParams } from 'react-router'
import { useOrganization } from './organization'
import { useOrganizationById } from 'hooks/organizations'
import { getIsLanguageRTL } from 'utils/languages'
import { useLocalStorage } from 'react-use'
import { useSiteById } from 'hooks/sites'

const LanguageContext = React.createContext(null)

const ALLOWED_LANGS = ['en-US', 'he-IL']

const DEFAULT_LANGUAGE = 'en-US'

function LanguageProvider({ children }) {
  const { i18n } = useTranslation()
  const [searchParams] = useSearchParams()

  let languageParam = searchParams.get('language')?.replace(/['"]+/g, '')

  if (!ALLOWED_LANGS.includes(languageParam)) {
    languageParam = null
  }

  const { kitchenId: kitchenIdFromParams, siteId } = useParams()

  const { organizationId } = useOrganization()
  const { organization } = useOrganizationById(organizationId)
  const { site } = useSiteById(siteId)
  const [localStorageLanguage, setLocalStorageLanguage] = useLocalStorage(
    'language',
    null
  )

  const [language, setLanguage] = React.useState(
    languageParam || localStorageLanguage || DEFAULT_LANGUAGE
  )
  const [isRTL, setIsRTL] = React.useState(getIsLanguageRTL(DEFAULT_LANGUAGE))

  const { kitchen } = useKitchenById(kitchenIdFromParams)

  const onChangeLanguage = React.useCallback(
    (lang) => {
      i18n.changeLanguage(lang)
      setLanguage(lang)
      const updatedIsRTL = getIsLanguageRTL(lang)
      setIsRTL(updatedIsRTL)
      document.dir = updatedIsRTL ? 'rtl' : 'ltr'
    },
    [i18n, setLanguage]
  )

  React.useEffect(() => {
    const language = kitchenIdFromParams || siteId ? //diner view
      languageParam ||
      localStorageLanguage ||
      site?.locale ||
      organization?.locale ||
      kitchen?.locale ||
      kitchen?.languages?.[0] : organization?.locale ||  DEFAULT_LANGUAGE //Admin/Customers view different configurations
    if (language) {
      onChangeLanguage(language)
      if(languageParam) {
        setLocalStorageLanguage(language)
      }
    }
  }, [
    i18n,
    kitchen,
    localStorageLanguage,
    onChangeLanguage,
    organization,
    site?.locale,
    languageParam,
    setLocalStorageLanguage
  ])

  const handleSetLanguage = useCallback(
    async (updatedValue) => {
      onChangeLanguage(updatedValue)
      setLocalStorageLanguage(updatedValue)
    },
    [onChangeLanguage, setLocalStorageLanguage]
  )

  const value = React.useMemo(
    () => ({
      isRTL,
      language,
      setLanguage: handleSetLanguage,
    }),
    [isRTL, language, handleSetLanguage]
  )

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  )
}

export const useLanguage = () => React.useContext(LanguageContext)

export default LanguageProvider
