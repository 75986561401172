import React from 'react'
import { createBrowserRouter, Navigate } from 'react-router'
import { RouterProvider } from 'react-router/dom'
import axios from 'axios'

import dinersRoutes from 'routes/diners'
import customersRoutes from 'routes/customers'
import adminRoutes from 'routes/admin'
import ErrorBoundary from 'views/ErrorBoundary'
import LoginPage from 'components/LoginPage'
import PrivacyPolicy from 'components/PrivacyPolicy'
import TermsOfUse from 'components/TermsOfUse'

//setup default configuration for axios
axios.defaults.withCredentials = true

const router = createBrowserRouter(
  [
    ...dinersRoutes,
    ...customersRoutes,
    ...adminRoutes,
    {
      path: '/',
      element: <Navigate replace to="/diners/kitchens-list" />,
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />,
    },
    {
      path: '/terms-of-use',
      element: <TermsOfUse />,
    },
    {
      path: '*',
      errorElement: <ErrorBoundary />,
      element: (
        <main style={{ padding: '1rem' }}>
          <p>There&apos;s nothing here!</p>
        </main>
      ),
    },
  ],
  {
    future: {
      v7_relativeSplatPath: true,
      v7_partialHydration: true,
      v7_startTransition: true,
    },
  }
)

export default function App() {
  return <RouterProvider router={router} />
}
