import React from 'react'
import SuspendedRoute from 'components/SuspendedRoute'
import { Outlet } from 'react-router'
import DinerView from 'views/DinerView'
import ErrorBoundary from 'views/ErrorBoundary'

const KitchenGreetingPage = React.lazy(() =>
  import('components/DinersView/KitchenGreetingPage')
)
const KitchenRegistrationPage = React.lazy(() =>
  import('components/DinersView/KitchenRegistrationPage')
)
const KitchensListPage = React.lazy(() =>
  import('components/DinersView/KitchensListPage')
)
const KitchenRegistrationCompletedPage = React.lazy(() =>
  import('components/DinersView/KitchenRegistrationCompletedPage')
)
const DinerDishesPage = React.lazy(() =>
  import('components/DinersView/DishesPage')
)
const DinerAttendancePage = React.lazy(() =>
  import('components/DinersView/DinerAttendance/DinerAttendancePage')
)
const SiteDishesPage = React.lazy(() =>
  import('components/DinersView/SiteDishesPage')
)
const SiteRegistrationPage = React.lazy(() =>
  import('components/DinersView/SiteRegistrationPage')
)
const SiteRegistrationCompletedPage = React.lazy(() =>
  import('components/DinersView/SiteRegistrationCompletedPage')
)

const SitePage = React.lazy(() => import('components/DinersView/SitePage'))

const dinersRoutes = [
  {
    path: 'diners',
    errorElement: <ErrorBoundary />,
    element: <DinerView />,
    children: [
      {
        path: 'kitchens-list',
        element: (
          <SuspendedRoute>
            <KitchensListPage />
          </SuspendedRoute>
        ),
      },

      {
        path: 'kitchens/:kitchenId',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: (
              <SuspendedRoute>
                <KitchenGreetingPage />
              </SuspendedRoute>
            ),
          },
          {
            path: 'registration',
            element: (
              <SuspendedRoute>
                <KitchenRegistrationPage />
              </SuspendedRoute>
            ),
          },
          {
            path: 'registration/completed',
            element: (
              <SuspendedRoute>
                <KitchenRegistrationCompletedPage />
              </SuspendedRoute>
            ),
          },
          {
            path: 'dishes',
            element: (
              <SuspendedRoute>
                <DinerDishesPage />
              </SuspendedRoute>
            ),
          },
        ],
      },

      {
        path: 'sites/:siteId',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: (
              <SuspendedRoute>
                <SitePage />
              </SuspendedRoute>
            ),
          },
          {
            path: 'kitchens-list',
            element: (
              <SuspendedRoute>
                <SitePage />
              </SuspendedRoute>
            ),
          },
          {
            path: 'attendances',
            element: (
              <SuspendedRoute>
                <DinerAttendancePage />
              </SuspendedRoute>
            ),
          },
          {
            path: 'registration',
            element: (
              <SuspendedRoute>
                <SiteRegistrationPage />
              </SuspendedRoute>
            ),
          },
          {
            path: 'registration/completed',
            element: (
              <SuspendedRoute>
                <SiteRegistrationCompletedPage />
              </SuspendedRoute>
            ),
          },
          {
            path: 'dishes',
            element: (
              <SuspendedRoute>
                <SiteDishesPage />
              </SuspendedRoute>
            ),
          },
        ],
      },
    ],
  },
]

export default dinersRoutes
