export default {
  virtual_waiter: 'virtual waiter',
  loading: 'loading',
  kitchens_list: 'kitchens list',
  register_or_sign_in: 'sign in',
  login_to_eat_id: 'connect to my EAT ID',
  create_your_eat_id: 'create my EAT ID',
  login_with_no_verifcation: 'login',
  already_registered: 'already registered',
  signed_in_user: 'signed in user',
  signout: 'sign out',
  logout: 'logout',
  anonymus: 'anonymus user',
  update_culinary_id: 'update culinary id',
  tofull_menu: 'skip to all dishes',
  yes: 'yes',
  no: 'no',
  other: 'other',
  send: 'send',
  enter: 'enter',
  continue: 'continue',
  done: 'done',
  im_done: 'I\'m Done',
  next: 'next',
  back: 'back',
  next_question: 'next question',
  add: 'add',
  change: 'change',
  duplicate: 'duplicate',
  search: 'search',
  choose: 'choose',
  close: 'close',
  locale: 'Set language',
  defaultPhonenumberCountry: 'Country',
  elaborate: 'elaborate',
  choose_relevant_options: 'Fill in all relevant fields',
  i_have_a_specific_diet: 'I have specific diet',
  you_dont_eat_specific_foods: 'don\'t get near my plate foods',
  allergies: 'Allergies',
  faith_restrictions: 'Faith restrictions',
  you_dont_eat_specific_foods_step_title: 'Which foods don\'t get near my plate?',
  things_i_avoid_or_reduce_step_title: 'Which foods do you try to reduce?',
  allergies_step_title: 'Which allergies do you have?',
  diets: 'Diets',
  diets_step_title: 'Do you have a specific diet?',
  no_specific_diet: 'No specific diet',
  no_food_restrictions: 'No food restrictions',
  things_i_avoid_or_reduce: 'things I try to reduce',
  im_allergic: 'I\'m Allergic',
  my_diet: 'My diet',
  things_i_dont_eat: 'Things that don\'t get near my plate',
  reduce_or_avoid: 'Reduce or avoid',
  allergic_to: 'Allergic to...',
  i_just_hate_eating: 'I just hate eating...',
  no_limitations: 'No specific preferences',
  can_you_elaborate: 'Can you please elaborate?',
  edit_personalized_preferences: 'edit personalized preferences',
  personalized_menu: 'personalized menu',
  type_ingredient_or_food: 'Type an ingredient or food type',
  relevant_options: 'relevant options',
  filters: 'filters',
  any_restrictions: 'Any food restrictions?',
  any_preferences: 'Any food preferences?',
  preference_step_subtitle: 'Fill in all relevant fields',
  any_more_restrictions: 'Any more restrictions?',
  if_thats_all_press_done: 'if that\'s all, press done',
  ask_for_changes: 'ask for changes',
  more_info: 'More Info',
  vegeterian: 'Vegetarian',
  my_reccomendations_for_you: 'Recommended for you',
  ask_without_the: 'ask without the',
  ingredients_selector_placeholder: 'No choices selected yet',
  coming_soon: 'coming soon',
  no_options: 'no options found',
  options: 'options',
  description: 'description',
  short_description: 'short description',
  longDescription: 'long description',
  warning: 'warning',
  price: 'price',
  default_price: 'default price',
  nutrition: 'nutrition',
  upgrades: 'upgrades',
  sizes: 'sizes',
  ingredients: 'ingredients',
  allergens: 'allergens',
  kitchen_warning: 'kitchen warnings',
  menu_options: 'Menus Options',
  choice: 'choices',
  addables: 'addables',
  addableComponents: 'addables',
  sidedish: 'side dishes',
  changes: 'changes',
  expand: 'expand',
  collapse: 'collapse',
  enter_phone_number: 'Enter phone number',
  login_plea: 'create your culinary.id',
  login_plea_subtext: 'We won\'t share this information with anyone',
  login_plea_comment: '(We only share dishes you can eat)',
  standalone_login_plea: 'create your culinary.id',
  standalone_login_plea_subtext: 'So we can prepare dishes suitable for you',
  no_login: 'without login',
  dish_possible_changes: 'Dish Possible Changes',
  ingredient: 'ingredient',
  dish: 'dish',
  remove: 'remove',
  put_aside: 'put aside',
  no_menus_relevant_now: 'No menus were found for the current time',
  menus_in_other_working_hours: 'The menus possible in other working hours',
  size_type_Small: 'small',
  size_type_Medium: 'medium',
  size_type_Large: 'large',
  size_type_glass: 'glass',
  size_type_pitcher: 'pitcher',
  size_type_bottle: 'bottle',
  size_type_shot: 'shot',
  size_type_Half: 'half',
  size_type_full: 'full',
  size_type_third: 'third',
  size_type_jigger: 'jigger',
  basic_ingredients: 'basic ingredients',
  excludable_ingredients: 'excludable ingredients',
  putaside_ingredients: 'putaside ingredients',
  choice_ingredients: 'choice ingredients',
  sideDish_ingredients: 'sideDish ingredients',
  addable_ingredients: 'addable ingredients',
  addableDishes_ingredients: 'addableDishes ingredients',
  translated_language: 'in english',
  tabs: {
    admin: {
      foodGroups: 'food groups',
      organizations: 'organizations',
      ingredients: 'ingredients',
      diets: 'diets',
      allergens: 'allergens',
      dishSizes: 'dish sizes',
      translations: 'translations',
      sites: 'sites',
      manufacturers: 'manufacturers',
      analytics: 'analytics',
      questionnaires: 'questionnaires',
      ingredientProperties: 'ingredient properties',
      ingredientPropertiesGroups: 'ingredient properties groups',
      ingredientGroups: 'ingredient groups',
      foodTypes: 'food types',
      foodTypeGroups: 'food type groups',
      manufacturedRecipes: 'manufactured recipes',
    },
    customers: {
      overview: 'overview',
      organization: 'organization',
      kitchens: 'kitchens',
      employees: 'employees',
      generalInfo: 'generalInfo',
      kitchenManagement: 'kitchen management',
      recipes: 'recipes',
      dishes: 'dishes',
      menus: 'menus',
      categories: 'categories',
      offerings: 'offerings',
      questions: 'questions',
      inventoryItems: 'inventory',
      inventoryProducts: 'inventory V3',
      translations: 'translations',
      services: 'daily service',
      servicesMenusPlanner: 'services menus planner'
    },
  },
  change_menu: 'menus we offer',
  settings: 'settings',
  connect: 'connect',
  my_list: 'my list',
  profile: 'preferences',
  deal: 'deal',
  empty_components_restriction_selector: 'Type a food type, ingredient or a flavor',
  empty_order: 'no dishes were added',
  order_summary: 'order summary',
  complete_order: 'complete order',
  edit_ingredient: 'Edit ingredient',
  create_ingredient: 'create ingredient',
  _id: '_id',
  name: 'display name',
  translation_heb: 'translation to hebrew',
  synonyms: 'synonyms',
  tags: 'tags',
  food_groups: 'food groups',
  is_searchable: 'is searchable',
  sub_ingredients: 'sub ingredients',
  excluded_in_diets: 'excluded in diet',
  what_would_you_like: 'what would you like?',
  you_can_tag_all_that_seems_to_your_liking: 'Add all relevant options',
  my_order_title_no_choices: 'No interesting dishes?',
  my_order_title: 'Great choices! Ordering is just around the corner.',
  my_order_subtitle: '(Please call the waiter the old-fashioned way...)',
  category: 'category',
  brand: 'brand',
  brands: 'brands',
  new_menu: 'new menu',
  new_dish: 'new dish',
  new_recipe: 'new recipe',
  new_category: 'new category',
  new_brand: 'new brand',
  categories_dishes: 'categories dishes',
  image_URL: 'image URL',
  categories: 'categories',
  create_ne_category: 'create new category',
  excludible_ingredients: 'excludible ingredients',
  delete: 'delete',
  reset: 'reset',
  save: 'save',
  cancel: 'cancel',
  are_you_sure_you_want_to_delete_this_dish: 'are you sure tou want to delete this dish',
  is_possible_to_putaside: 'possible to put aside',
  added_price: 'added price',
  new_kitchen: 'new kitchen',
  kitchen: 'kitchen',
  kitchens: 'kitchens',
  website: 'website',
  image: 'image',
  address: 'address',
  intentions: 'intentions',
  currency: 'currency',
  dishes: 'dishes',
  add_new_dish: 'add new dish',
  recipes: 'recipes',
  add_new_recipe: 'add new recipe',
  menu: 'menu',
  menus: 'menus',
  menu_categories: 'menu categories',
  you_can_drag_and_drop_dishes_to_reorder_them: 'you can drag and drop dishes to reorder them',
  you_can_drag_and_drop_categories_to_reorder_them: 'you can drag and drop categories to reorder them',
  add_category: 'add category',
  add_subcategory: 'add sub-category',
  edit_category: 'edit category',
  category_dishes_linking: 'category dishes linking',
  sort_categories: 'sort categories',
  create_your_first_category: 'create the first menu category',
  add_station: 'add station',
  add_dish: 'add dish',
  delete_menu: 'delete menu',
  are_you_sure_you_want_to_delete_this_menu: 'are you sure you want to delete this menu',
  personal_preferences: 'personalized',
  you_dont_have_any_preferences: 'you haven\'t set any preferences',
  would_you_like_to_personalize_the_menu: 'would you like to personalize the menu?',
  personalize: 'personalize',
  customer_ingredients_and_recipes_selector_placeholder: 'search ingredients and recipes of yours',
  empty_components_ingredients_and_recipes_selector: 'no ingredients or recipes selected yet',
  add_subingredients: 'add sub-ingredients',
  delete_dish: 'delete dish',
  composition: {
    name: 'composition',
    basic: 'basic',
    choices: 'choices',
    addables: 'addables',
    sideDishesChoices: 'side dishes choices',
    modifiers: {
      putAsidable: 'can be put aside',
      removable: 'can be removed',
      additionalPrice: 'additional price',
      allowChoiceOfNone: 'allow choice of "none"',
      defaultChosen: 'default choice'
    },
  },
  add_menu: 'add menu',
  are_you_sure_you_want_to_delete_this_category: 'are you sure you want to delete this category',
  delete_category: 'delete category',
  recipes_and_ingredients: 'recipes and ingredients',
  side_dishes: 'side dishes',
  workingHours: 'working hours',
  start_time: 'start',
  end_time: 'end',
  drinks: 'drinks',
  upload_image: 'upload image',
  drag_n_drop_placeholder: 'Drag & drop an image here, or click to select a file',
  choiceOfNone: 'none',
  advances_choices_modifier_options: 'advances choices modifier options',
  modifier_min_choices_count: 'minimum choices',
  modifier_max_choices_count: 'maximum choices',
  clear: 'clear',
  employees: 'employees',
  diet: 'diet',
  food_group: 'food group',
  organization: 'organization',
  employee: 'employee',
  recipe: 'recipe',
  inventoryItem: 'inventory item',
  inventoryItems: 'inventory items',
  inventoryProduct: 'inventory product',
  inventoryProducts: 'inventory products',
  login: {
    login_with_google: 'Login with Google',
    login: 'login',
    register: 'register',
    channel: 'Verification channel',
    email: 'Email',
    password: 'Password',
    sms: 'SMS',
    call: 'call',
    whatsapp: 'WhatsApp',
    enter: 'Enter',
    verification_code: 'Verification code',
    failed_recieved_code: 'Haven\'t received the code?',
    send_again: 'Send again',
    fix_number_label: 'Want to fix your number?',
    fix_number_request: 'fix number',
    code_expires_in: 'Code expires in',
    code_sent_valid_time: 'The code sent is valid for 10min',
    didnt_recieve_verification_code: 'Didn\'t recieve verification code?',
    phone_number_error: 'Phone number error',
    verification_code_error: 'Verification code error'
  },
  sec: 'sec',
  error: 'error',
  try_again: 'try again',
  allergy_general_warning: 'Your safety matters. If you have food allergies or a related medical condition, inform staff beyond system updates. Unanticipated dish changes could be critical to you.',
  accepted_and_read: 'Got it!',
  customer_ingredients_selector_placeholder: 'search ingredients',
  customer_foodgroups_selector_placeholder: 'search food groups',
  phonenumber_must_be_atleast_8_digits: 'phone number must be atleast 8 digits',
  saved: 'saved',
  categorized_dish_type: 'categorized dish type',
  all: 'all',
  food: 'food',
  drink: 'drinks',
  dessert: 'desserts',
  specials: 'specials',
  terms_of_use_and_privacy_policy_label: 'When registering or logging in, you agree to our',
  i_agree_to_the: 'I agree to the',
  terms_of_use: 'Terms of Use',
  '&': '&',
  privacy_policy: 'Privacy Policy',
  operationalName: 'operational name',
  displayName: 'display name',
  dishName: 'dish name',
  phonenumber: 'phone number',
  role: 'role',
  foodGroups: 'food groups',
  subIngredients: 'sub-ingredients',
  contains: 'contains',
  mayContain: 'may contain',
  producedFrom: 'produced from',
  mayContainIngredients: 'may contain ingredients',
  mayContainFoodGroups: 'may contain foodGroups',
  producedFromIngredients: 'produced from ingredients',
  producedFromFoodGroups: 'produced from foodGroups',
  excludedInDiets: 'excluded in diets',
  computed_details: 'computed details',
  actions: 'actions',
  new: 'new',
  title: 'title',
  subtitle: 'subtitle',
  are_you_sure_you_want_to_delete_this_item: 'are you sure you want to delete this item?',
  category_type: 'category type',
  email_or_password_incorrect: 'Username or password incorrect. Try again?',
  login_attempt_failed: 'Login attempt failed.',
  category_new_or_imported: 'linked or new',
  link_category_to_category_collection: 'create linked category',
  category_hard_link: 'hard linked',
  or: 'or',
  create_new_category: 'create new category',
  linked_categories: 'linked categories',
  category_details: 'category details',
  no_dishes_fit_for_diner_category_type: 'Unfortunately there are no dishes suitable to your preferences in this section. ',
  no_dishes_found_with_search: 'No dishes found with what you are searching for',
  show_filtered_dishes: 'I will show you the dishes and the reason it was filtered out.',
  filtered_out_dish: 'filtered out dish because contains',
  diner_allergy_consent: 'I\'ve read and consent',
  all_choices_filtered_out: 'All choice ingredients were filtered out',
  filtered_out_reasons: 'filtered out reasons',
  dish_contains: 'the dish contains',
  dish_not_verified: 'the dish ingredients was not verified hence filtered out to avoid risks',
  no_dishes_linked_to_kitchen: 'no dishes instances were linked to this kitchen',
  dishInstance: 'dish instance',
  kitchens_list_for_management: 'kitchen list for management',
  dishes_potential: 'dishes potential',
  no_dishes_chosen_yet: 'no dishes chosen yet',
  dish_not_linked_to_any_category_served: 'dish not linked to in any category served in the kitchen',
  kitchen_tags: 'kitchen tags',
  kitchenTags: 'kitchen tags',
  new_kitchenTag: 'new kitchen tag',
  dishKitchenTags: 'dish tags',
  supported_languages: 'supported languages',
  change_language: 'change language',
  phoneNumber: 'phone number',
  allow_only_in_specific_dates_range: 'Is this offered only in a specific date range?',
  availableDates: 'available dates',
  fromDate: 'from',
  toDate: 'to',
  is_active: 'active',
  warnings: 'warnings',
  allow_only_in_specific_hours_in_days: 'Is this offered only in specific days and times?',
  availableTimes: 'available times',
  allow_only_in_specific_days_and_times: 'allow only in specific days and times',
  availabilityHoursInDays: 'days and times available',
  no_limit: 'no limitation',
  begining_of_time: 'begining of time',
  end_of_time: 'end of time',
  none: 'none',
  select_all: 'select all',
  unselect_all: 'unselect all',
  empty_potential_dishes_filtered: 'no more potential dishes were found for these filters',
  min: 'min',
  max: 'max',
  add_dishes: 'add dishes',
  dishesInstances: 'dishes made by kitchen',
  dishSizes: 'dish sizes',
  allow_a_few_dish_sizes: 'allow a few dish sizes',
  sizeUnit: 'unit',
  sizeAmount: 'amount',
  show_only_missing_translations: 'show only missing translations',
  export_to_menu: 'export to menu',
  export: 'export',
  no_category_name: 'no category',
  minutes: 'minutes',
  no_end_date: 'no end time',
  no_start_date: 'no start time',
  add_times_and_days_range: 'add times and days ranges',
  add_dates_range: 'add dates range',
  different_dish_sizes_question: 'are there a different sizes to this dish?',
  faithRestriction: 'faith restriction',
  faithRestrictions: {
    question: 'Do you have a faith restriction?',
    none: 'none',
    kosher: 'kosher',
    kosher_certified_kitchen: 'certified kosher',
    kosher_no_certificate: 'kosher (no certificate required)',
    kosher_dishes: 'kosher dishes',
    halal: 'halal',
    halal_certification: 'certified halal',
    halal_meat: 'halal meat and poultry',
    halal_alcohol_free_options: 'alcohol free dish options',
  },
  no_faith_restriction: 'no faith restriction',
  no_restrictions: 'I don\'t have any restrictions',
  soon: 'soon',
  have_any_questions: 'have any questions?',
  contact_us: 'contact us',
  powered_by: 'powered by',
  avoidORReduce_dish_basic_ingredients_contain: 'The dish contains things you are trying to avoid or reduce',
  avoidORReduce_dish_choices_ingredients_contain: 'The dish choices contain things you are trying to avoid or reduce',
  avoidORReduce_dish_mandatory_ingredients_contain: 'The dish contain things you are trying to avoid or reduce',
  dish_not_suited_to_faith_restriction: 'dish doesn\'t suite your faith restriction',
  welcome: {
    morning: 'good morning',
    afternoon: 'good afternoon',
    evening: 'good evening',
    night: 'good night',
    to: 'welcome to',
  },
  all_dishes: 'all',
  my_dishes: 'personal menu',
  i_desire: 'I desire',
  account: 'account',
  reset_to_all: 'reset to all',
  login_step: {
    title: 'Connect to your EAT ID',
    subtitle: '',
    register: 'Sure! I don\'t want to fill this out again',
    optout: 'Maybe next time',
  },
  register_step: {
    title: 'Want to save your EAT ID?',
    subtitle: 'save time for next time we meet',
  },
  got_it: 'got it',
  cookingMethodsOrFlavors: 'cooking methods and flavors',
  dishTypeFoodGroup: 'dish types',
  recalculating_dishes: 'recalculating dishes for you',
  how_eatid_works: 'How EAT ID works',
  eattlv: {
    contestBtn: 'free dish?',
    contestTitle: 'Fancy a free dish?',
    step1: 'Use the Virtual Waiter and register',
    step2: 'Upload a photo from the festival and tag',
    step3: 'Tell us a funny story following an ingredient or dish name in a menu you haven\'t heard of or confused you',
  },
  under_the_terms: 'under the terms',
  onboardingBtn: 'EAT ID',
  onboardingWhyShouldI: 'why you should register',
  organization_admins: 'organization administrators',
  language_default: 'language',
  dishType: 'dish type',
  date: 'date',
  time: 'time',
  order: 'order',
  order_dishes: 'order dishes',
  order_categories: 'order categories',
  custom_order_dishesInstances: 'custom order dishes',
  reset_to_default_dishes_order: 'reset to default order',
  missing_translation: 'missing translation',
  now_offering: 'offering now',
  anytime_offering: 'all',
  edit: 'edit',
  multi_edit: 'Multiple changes',
  edit_eat_id: 'edit your EAT ID',
  service_menus: 'Menus',
  should_replace_or_add: 'Should we replace or add the changes?',
  replace: 'replace',
  share_your_personalized_menu: 'share your personalized menu',
  copy_share_personalized_menu_link: 'copy share personalized menu link',
  copied_to_clipboard: 'copied to clipboard',
  chosen_components: 'chosen components',
  not_chosen_yet: 'not chosen yet',
  no_results: 'no results',
  add_new_component: 'add new component',
  customer_ingredient_creation_warning: 'new ingredients created by you must be validated by system admin hence might be changes and modified',
  desirable_foodGroups: 'tags and food groups',
  categories_and_tags: 'categories and tags',
  ids_and_makatim: 'ids and external serial numbers',
  additional_information: 'additional info',
  add_new_category: 'add new category',
  deleted_category: 'deleted category',
  desirable_foodGroups_helpers: 'tagging dishes with food groups will help diners better find dishes matching their preferences. We strongly suggest tagging as many suitable food groups that fit the dish.',
  externalSystems: 'external systems',
  export_to_excel: 'export to CSV',
  amount: 'amount',
  type: 'type',
  verified: 'verified all dish ingredients',
  mark_as_new: 'Mark as new',
  mark_as_recommended: 'Mark as recommended',
  recommended: 'recommended',
  is_sidedish: 'mark as sidedish',
  previous_price: 'previous price',
  previous_price_change: 'previous price change date',
  new_price: 'current price',
  latest_price_change: 'current price change date',
  added_dish_instances: 'added dishes successfully',
  multi_add_dishes: 'Add dishes to multiple kitchens',
  secrect_recipe_question: 'Would you like to keep the recipe secret?',
  secret: 'secret',
  added_by_customer: 'Requires admin review',
  missing_ingredients: 'Pay attention there are missing ingredients',
  foodGroup: 'foodGroup',
  parentFoodGroups: 'parent food groups',
  isSearchable: 'is searchable',
  missingIngredients: 'missing ingredients',
  isDishSearchable: 'searchable in dishes',
  isCookingMethodsOrFlavors: 'cooking methods\\flavors',
  isGeneric: 'is generic',
  manufacturer: 'manufacturer',
  menu_dishes: 'menu dishes',
  edit_categories: 'edit categories',
  intent: {
    label: 'intent',
    sitdown: 'sitdown',
    delivery: 'delivery',
    pickup: 'pickup',
  },
  reject_customer_ingredient: {
    title: 'Reject',
    move_to_recipes: 'move to recipes',
    replace_with_another_ingredient: 'replace with another ingredient',
  },
  affected_food_components: 'affected food components',
  menuInstances_controller: 'menu services manager',
  menuInstance_dialog: {
    title: 'service menu instance',
    reoccurring: 'reoccurring',
    singleInstance: 'only this instance',
    allInstances: 'all instances',
    nowAndAfterInstances: 'from this instance on',
    reolccurring_save_title: 'Update reoccurring menu instances',
    reoccurring_delete_tite: 'Delete reoccurring menu instances',
    singleInstance_texthelper: 'updating only this instance will detach the instance from the reoccurring set',
    nowAndAfterInstances_texthelper: 'this instance and there after will be create a new reoccurring set detached from the current set',
  },
  register_diner_to_event: 'Save your EAT ID',
  register_diner_to_event_subtext: 'enter your phone number so we can recognize you in the event!',
  diner_doesnt_exist: 'User doesn\'t exist',
  diner_with_phonenumber_given_does_not_exist: 'User doesn\'t exist',
  temperary_dish_tags: {
    label: 'temperary tags',
    customTag_vegan: 'vegan',
    canBeVegan: 'can be vegan',
    warning: 'These tags will be removed in the future and are meant for CSV export only',
  },
  menuInstance_controller_new_kitchen_warning: 'Please save the new kitchen before managing the service menus',
  dinerQuestions: {
    excludeFoodComponent: 'Do you exclude in your diet: ',
    foodComponentOpinion: 'What do you think of: '
  },
  love_it: 'love it',
  hate_it: 'hate it',
  depends: 'depends',
  on_premise_days: {
    question: 'On which days do you typically come into the office?',
    never: 'Never',
    everyday: 'Everyday',
    changes_weekly: 'Changes weekly',
  },
  lunch_meal_source: {
    question: 'Where do you usually prefer to eat lunch?',
    options: {
      in_the_office_cafe: 'In the office cafe',
      at_the_restaurants_near_the_office: 'At the restaurants near the office',
      bring_food_from_home: 'I bring food from home',
      order_food_for_delivery: 'Order food for delivery',
      depends_on_what_the_cafe_is_serving_that_day: 'Depends on what the cafe is serving that day',
    }
  },
  lunch_meal_wishlist: {
    question: 'What’s missing from our cafe menus?',
    placeholder: 'Enter your meal wishlist here',
  },
  proteins_options: {
    questions: {
      'main_proteins': 'Which protein do you generally prefer?',
      'main_proteins_breakfast': 'Which protein do you prefer at breakfast?',
      'main_proteins_lunch': 'Which protein do you prefer at lunch?',
      'main_proteins_dinner': 'Which protein do you prefer at dinner?',
    },
    grain_based: 'Grain based (seitan)',
    legumes: 'Legumes (chickpeas / beans)',
    soy_based: 'Soy-Based (tofu / tempeh)',
    fish: 'Fish',
    no_preference: 'I don\'t have a strong preference',
    dont_eat_plant_based_substiudes: 'I don\'t eat plant based substitutes',
    chicken: 'Chicken',
    red_meat: 'Red meat',
    plant_based_substiude: 'Plant based substiute',
    none: 'None of the above',
  },
  carbs_options: {
    questions: {
      'main_carbs': 'Which carbs do you generally prefer?',
      'main_carbs_breakfast': 'Which carbs do you prefer at breakfast?',
      'main_carbs_lunch': 'Which carbs do you prefer at lunch?',
      'main_carbs_dinner': 'Which carbs do you prefer at dinner?',
    },
    rice: 'Rice',
    pasta: 'Pasta',
    bread: 'Bread',
    potatoes: 'Potatoes',
    no_preference: 'I don\'t have a strong preference',
    none: 'None of the above',
  },
  desserts_options: {
    questions: {
      'desserts': 'Which desserts do you generally prefer?',
      'desserts_breakfast': 'Which desserts do you prefer at breakfast?',
      'desserts_lunch': 'Which desserts do you prefer at lunch?',
      'desserts_dinner': 'Which desserts do you prefer at dinner?',
    },
    cake: 'Cake',
    fruit: 'Fruit',
    pastry: 'Pastry',
    ice_cream: 'Ice cream',
    no_preference: 'I don\'t have a strong preference',
    none: 'None of the above',
  },
  choose_multiple_options: 'you can choose more than one',
  dinerEatIDNotes: {
    question: 'Unique preferences? No worries!',
    subtitle: 'Write us and we will keep it out of your plate',
  },
  signupStep: {
    title: 'Join us to redefine dining',
    subtitle: 'Sign up and unlock your personalized culinary future',
  },
  most_common: 'Most common searches',
  search_results: 'search results',
  analytics_page: {
    dish_match_list: 'Dishes and diners match',
    diners_chart: 'Diners diets',
    mostExcludedDinersFoodComponents: 'Foods most excluded by diners',
    brand_most_occuring_food_components: 'Most ocurring food components',
    brand_most_occuring_specific_ingredients: 'Ingredients',
    brand_most_occuring_foodGroups: 'Food groups',
    dish_match_allergies_and_Diets: 'Dish allergies and diets',
    dish_excluded_in_diet: 'Excluded in Diets',
    dish_name: 'Dish name',
    dish_allrgens: 'Allergies',
    dish_matches: 'Matches',
    categoryDishType: 'category dish type',
    categories: 'categories',
    restrictedRegulars: 'Regulars with limitations',
    unrestrictedRegulars: 'Regulars with no limitations',
    occasionalMenuDrivenFlexibles: 'Menu driven',
    nonCafetiria: 'Eating outside',
    missed: 'missed'
  },
  kitchen_texts: {
    label: 'kitchen texts',
    onPremise: 'on-premise flow',
    kitchenOnPremiseTitle: 'Tailored Dining, Anywhere',
    kitchenOnPremiseSubtitle: 'Elevate Your Culinary Choices',
    kitchenRegistration: 'registration flow',
    kitchenRegistrationTitle: 'Help us cater to your needs',
    kitchenRegistrationSubtitle: 'Together we can reduce food overproduction',
    registrationCompleted: 'registration completed',
    kitchenRegistrationCompletedTitle: 'Thanks for registering!',
    kitchenRegistrationCompletedSubtitle: 'See you at the event',
  },
  questionnaire_texts: {
    label: 'questionnaire texts',
    questionnaireRegistrationStep: 'questionnaire registration step',
    questionnaireRegistrationStepTitle: 'Want to save your EAT ID?',
    questionnaireRegistrationStepSubtitle: 'save time for next time we meet',
    questionnaireLoginStep: 'questionnaire login step',
    questionnaireLoginStepTitle: 'Connect to your EAT ID',
    questionnaireLoginStepSubtitle: '',
    questionnairePhonenumberStep: 'questionnaire phonenumber step',
    questionnairePhonenumberStepTitle: 'With which phonenumber did you register?',
    questionnairePhonenumberStepSubtitle: '',
  },
  site_texts: {
    label: 'site texts',
    kitchensListTitle: 'See what who will cater you',
    kitchensListSubtitle: 'See what will fit your culinary preferences',
    onPremise: 'on-premise flow',
    siteOnPremiseTitle: 'Tailored Dining, Anywhere',
    siteOnPremiseSubtitle: 'Elevate Your Culinary Choices',
    siteRegistration: 'registration flow',
    siteRegistrationTitle: 'Help us cater to your needs',
    siteRegistrationSubtitle: 'Together we can reduce food overproduction',
    registrationCompleted: 'registration completed',
    siteRegistrationCompletedTitle: 'Thanks for registering!',
    siteRegistrationCompletedSubtitle: 'See you at the event',
    siteAttendanceTitle: 'When are you attending our site?',
    siteAttendanceSubtitle: '',
    siteAttendanceCompletedTitle: 'Thanks for updating your attendance!',
    siteAttendanceCompletedSubtitle: 'If their are any updates, you can always update later',
  },
  showQuestionnairePhonenumberStep: 'show questionnaire phonenumber step',
  disableRegistrationCompletionToMenuButton: 'disable registration completion to menu button',
  forceRegistration: 'force registration',
  questionnaire_section: 'questionnaire section',
  questionnaireSteps: 'questionnaire steps',
  accumulatedFoodComponents: 'all food components contained',
  foodcomponent_not_recognized: 'the food component is not recognized and is missing information',
  foodcomponent_not_recognized_action_text: 'complete the missing information by adding a:',
  made_here: 'new recipe',
  bought_inventoryproduct: 'new inventory product',
  ingredientGroup: {
    label: 'ingredientGroup',
    includesCombinations: 'includes combinations',
    excludesCombinations: 'excludes combinations',
  },
  why_are_we_asking: 'Why are we asking?',
  on_premise_days_step_explanation: 'Knowing your dining times helps us cut food waste by reducing excess dish preparation!',
  lunch_meal_source_step_explanation: 'We aim to create a menu that satisfies everyone, so you won\'t need to look elsewhere.Knowing your current food source will guide us.',
  phonenumber_step_explanation: 'Your phone number is our way to identify you for now and for future encounters.',
  restrictions_step_explanation: 'All of us have things we avoid, reduce or simply don\'t want to eat. By letting us know your preferences we can tailor menus to your needs - only showing dishes that fit your preferences. This information is private to you.',
  to_the_menu: 'to the menu',
  no_menu_available_now: 'There is no menu available now. You can checkout again later!',
  now: 'now',
  search_something_above: 'Search anything you can think of and add it to your list',
  dish_matches: 'dish matches',
  diners_distributions: 'diners distributions',
  food_components_statistics: 'food components statistics',
  total_diners: 'total diners',
  morning_conference_preferences: {
    question: "Would you like breakfast on arrival?",
    fruits: "fruits",
    vegetables: "vegetables",
    sweet_pastries: "sweet pastries",
    savory_pastries_or_sandwitches: "sandwitches or savory pastries",
    no_preference: "I don't have a strong preference",
    none: "No, I don’t need a breakfast",
  },
  conference_breaks_preferences: {
    question: "During breaks...",
    fruits: "fruits",
    vegetables: "vegetables",
    sweet_pastries: "sweet pastries",
    savory_pastries_or_sandwitches: "sandwitches or savory pastries",
    no_preference: "I don't have a strong preference",
    none: "usually, I don’t eat, I'm more focused on networking",
  },
  wishlist_placeholder: 'Enter your meal wishlist here',
  ingredientType: 'ingredient type',
  baseIngredient: 'base ingredient',
  manufacturedProduct: 'manufactured product',
  foodType: 'food type',
  DinersDietsEulerDiagram: 'diners diets',
  foodGroupType: 'food group type',
  foodGroupTypes: {
    ingredientsAggregator: 'ingredients aggregator',
    foodTypesAggregator: 'foodTypes aggregator',
    cookingMethod: 'cooking method',
    questions: 'questions',
    dishType: 'dishType',
    flavor: 'flavor',
  },
  originalContainsLabel: 'original contains label',
  originalMayContainsLabel: 'original may contains label',
  calculatedDependantFoodComponents: 'calculated dependant food components',
  calculating: 'calculating',
  calculate: 'calculate',
  site_service_time: 'site service time',
  add_to_order: 'add to order',
  cart: {
    title: 'current order',
    changeRequest: {
      remove: 'removed',
      putAside: 'on the side',
    },
    edit_dish: 'edit',
    remove_from_cart: 'remove',
  },
  foodComponents_not_found_title: 'haven\'t found what you\'re looking for?',
  foodComponents_not_found_message: 'We might have missed it or don\'t support it yet. Add it to your list and we\'ll try to support it ASAP!',
  request_new_restriction: 'request new restriction',
  no_results_found_restrictions_options: 'no results found for searched value',
  diner_created_foodComponent_warning: 'new food components created by you must be validated by system admin hence might be changes and modified, and will not be taken under consideration when creating your personal menu',
  days: {
    long: {
      sunday: 'Sunday',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
    },
    short: {
      sunday: 'Sun',
      monday: 'Mon',
      tuesday: 'Tue',
      wednesday: 'Wed',
      thursday: 'Thu',
      friday: 'Fri',
      saturday: 'Sat',
    }
  },
  attending: 'Attending',
  notAttending: 'Not attending',
  serviceManagement: {
    dish: {
      verified: 'as planned',
      ingredient_changes: 'ingredient changes',
      switched: 'dish switched',
      removed: 'dish removed',
      isAvailable: 'available',
      isNotAvailable: 'not available',
      units: {
        plates: 'plates',
        gastronoms: 'gastronoms',
        pieces: 'pieces',
        kg: 'kg',
      }
    }
  },
  dishesSlotsNumber: 'Number of dishes slots',
  allDay: 'All day',
  add_availableMenu: "add available menu",
  service_manager_week: 'Week',
  servicesManager: {
    availableMenus: 'available menus',
    pre_service: 'pre service',
    during_service: 'during service',
    after_service: 'after service',
  },
  related_diners: 'related diner profiles',
  diner_back_to_me: 'back to my profile',
  add_diner: 'add diner profile',
  diner_nickname: 'nickname',
  randomize_avatar: 'randomize avatar',
}
