import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
  Alert,
  Button,
  FormControl,
  Stack,
  TextField,
  Divider,
} from '@mui/material'
import { useUser } from 'contexts/user'
import { useLocation, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import RegistrationDisclaimers from '../DinersView/RegistrationDisclaimers'

import google_image from '../../icons/google.svg'
import '../../index.css'

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup.string('Enter your password').required('Password is required'),
})

export default function LoginForm() {
  const { signin } = useUser()
  const navigate = useNavigate()

  const { t } = useTranslation()
  const [error, setError] = React.useState()
  const location = useLocation()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { email, password } = values
      try {
        const user = await signin(email, password)

        if (user.role === 'admin') return navigate('/admin/organizations')
        if (user.role) return navigate('/customers/kitchens')
        if (location?.state?.from) return navigate(location?.state?.from)
      } catch (error) {
        if (error?.response?.status === 401) {
          setError('unauthorized')
        } else {
          setError('internal_server_error')
        }
        console.error(error)
      }
    },
  })
  const handleSubmitLoginWithGoogle = async () => {
    window.location.href = '/api/auth/google'
  }
  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Button
        className="google-button"
        id={'login-with-GOOGLE'}
        fullWidth
        onClick={handleSubmitLoginWithGoogle}
      >
        <img src={google_image} alt="Google Logo" />
        {t('login.login_with_google')}
      </Button>
      <Divider textAlign="center" sx={{ my: 2 }}>
        {t('or')}
      </Divider>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          sx={{
            width: 500,
          }}
        >
          <FormControl margin="normal">
            <TextField
              fullWidth
              id="email"
              name="email"
              label={t('login.email')}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </FormControl>

          <FormControl margin="dense">
            <TextField
              fullWidth
              id="password"
              name="password"
              label={t('login.password')}
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </FormControl>

          {error && (
            <Alert severity="error">
              {error === 'unauthorized'
                ? t('email_or_password_incorrect')
                : t('login_attempt_failed')}
            </Alert>
          )}

          <Button color="primary" variant="contained" fullWidth type="submit">
            {t('login.enter')}
          </Button>
        </Stack>
      </form>
      <RegistrationDisclaimers />
    </Stack>
  )
}
